import React from "react"
import Album from "../components/album"

export default (props) => {
  const formatsAvailable = "four varieties of 10\" vinyl LP (3 colored varieties + black), digital download"
  return (
    <Album
      title="Howlin' EP"
      recordedDate="Nov 2014"
      recordedLocation="Look To Listen (fka Kutch-One Studio), Greenpoint, Brooklyn"
      engineers="Mike Kutchman"
      releaseDate="April 21, 2015"
      recordLabel="Shoulder Tap Records"
      catalogNumber="SHT014"
      formatsAvailable={formatsAvailable}
      location={props.location} />
  )
}
